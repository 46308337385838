import React, { useRef, useState } from "react"
import * as D3 from "d3"
import { Dropdown } from "semantic-ui-react"
import SEO from "../../../components/seo"
import useContainerDimensions from "../../../components/useContainerDimensions"
import stackedBarChart from "../../../components/stackedBarChart"
import styles from "./cardiovascular-disease-markers.module.css"
import "../../general.css"
import CholesterolData from "../../../data/cholesterol-levels.json"
import {
    chartTitleTextStyle,
    vizBackgroundStyle,
    PlantsGreenColor,
    AnimalsRedColor
} from "../../../defines";

const Title = "Cardiovascular disease markers";

const GenderOptions = [
    { key: "m", text: "Men", value: "Men" },
    { key: "w", text: "Women", value: "Women" },
];

const MarkerOptions = [
    { key: "c", text: "Cholesterol (mmol/l)", value: "Cholesterol" },
    { key: "a", text: "Apolipoprotein B (mg/dl)", value: "Apo B" },
];

const CardiovascularMarkers = ({ mobile }) => {
    const [selectedGender, setSelectedGender] = useState("Men");
    const [selectedMarker, setSelectedMarker] = useState("Cholesterol");
    let GraphAspectRatio = 3 / 3;
    if (mobile) {
        GraphAspectRatio = 3 / 3;
    }
    const graphDivRef = useRef();
    let { width } = useContainerDimensions(graphDivRef);
    if (!mobile) {
        width = .75 * width;
    }
    const svgWidth = Math.max((width - 10), 0);
    const svgHeight = Math.max((width - 10) / GraphAspectRatio, 0);

    const colorScale = D3.scaleOrdinal()
        .domain(["HDL", "NonHDL", "Apo B"])
        .range([PlantsGreenColor, AnimalsRedColor, AnimalsRedColor]);

    const keys = (selectedMarker === "Cholesterol") ? ["HDL", "NonHDL"] : ["Apo B"]
    const series = D3.stack()
        .keys(keys)(CholesterolData[selectedGender]);

    const renderData = () => {
        if (typeof document === "undefined") {
            return;
        }

        const cholesterolSvg = D3.select(".cholesterolSvg");
        let barChartG = cholesterolSvg.select(".barChartG");
        if (barChartG.empty()) {
            barChartG = cholesterolSvg.append("g");
            barChartG.attr("class", "barChartG");
        }

        barChartG.call(stackedBarChart, {
            series,
            width: svgWidth,
            height: svgHeight,
            margin: mobile ? { left: 58, top: 20, right: 0, bottom: 0 } : { left: 35, right: 0, top: 0, bottom: 25 },
            keysScaleAccessor: d => d.Diet,
            colorScale,
            isHorizontal: mobile,
            tooltipClass: styles.tooltip,
            mobile
        });
    };

    const onGenderChanged = (event, result) => {
        let { value } = result || event.target;
        setSelectedGender(value);
    };

    const onMarkerChanged = (event, result) => {
        let { value } = result || event.target;
        setSelectedMarker(value);
    };

    renderData();
    return (
        <>
            <SEO title={Title} />
            <div className="contentDiv">
                <h2 className="chartTitle" style={chartTitleTextStyle(mobile)}>{Title}</h2>
                <div className="vizBackground" style={vizBackgroundStyle(mobile)}>
                    <div className={styles.tooltip} style={{ opacity: 0 }} />
                    <div style={{ textAlign: "center", marginBottom: 10 }}>
                        <Dropdown
                            basic
                            selection
                            options={GenderOptions}
                            value={selectedGender}
                            onChange={onGenderChanged}
                        />
                        <Dropdown
                            basic
                            selection
                            options={MarkerOptions}
                            value={selectedMarker}
                            onChange={onMarkerChanged}
                            style={{marginLeft: mobile ? 0 : 10 }}
                        />
                    </div>
                    <div className="FlexGrow" ref={graphDivRef} style={{ textAlign: "center" }}>
                        <svg className="cholesterolSvg" width={`${svgWidth}px`} height={`${svgHeight}px`} />
                    </div>
                    <div className="citation" style={{ textAlign: "center", marginBottom: 10 }}>
                        HDL - High density lipoprotien - "good" cholesterol <br />
                        Apolipoprotein B - the main protein constituent of "bad" cholesterol
                    </div>
                    <div className="citation">
                        Data source: <a href="https://www.nature.com/articles/ejcn2013248">
                        Serum concentrations of cholesterol, apolipoprotein A-I and apolipoprotein B in a total of 1694 meat-eaters, fish-eaters, vegetarians and vegans</a><br />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardiovascularMarkers;
